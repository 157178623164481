import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/platform/:platformId",
    name: "Platform detail",
    component: () =>
      import(
        /* webpackChunkName: "platform" */ "@/views/PlatformDetailPage.vue"
      ),
    props: (route) => ({ platformId: route.params.platformId }),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/views/NotificationsPage.vue"
      ),
  },
  {
    path: "/usage-data-host",
    name: "Usage data hosts",
    component: () =>
      import(
        /* webpackChunkName: "datahosts" */ "@/views/UsageDataHostsPage.vue"
      ),
  },
  {
    path: "/usage-data-host/:dataHostId",
    name: "Usage data host detail",
    component: () =>
      import(
        /* webpackChunkName: "datahost" */ "@/views/UsageDataHostDetailPage.vue"
      ),
    props: (route) => ({ dataHostId: route.params.dataHostId }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
