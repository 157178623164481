<template>
  <v-data-table
    :items="platforms"
    :headers="headers"
    item-key="id"
    :items-per-page="1000"
    hide-default-footer
    :loading="loading"
    :search="filterText"
  >
    <template #top>
      <v-container class="pb-0" fluid>
        <v-row no-gutters>
          <v-col
            v-if="!hideContacts"
            class="align-self-center mb-2 hidden-sm-and-down"
          >
            <span>
              <span class="text--secondary">Columns: </span>
              <v-btn-toggle v-model="columnSet" dense>
                <v-btn value="reports">{{ $t("label.button.info") }}</v-btn>
                <v-btn value="contacts">{{
                  $t("label.button.contacts")
                }}</v-btn>
              </v-btn-toggle>
            </span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="platforms.length > 10">
            <v-text-field
              v-model="filterText"
              :label="$t('label.input.search')"
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #item.name="{ item }">
      <router-link
        :to="{ name: 'Platform detail', params: { platformId: item.id } }"
        >{{ item.name }}</router-link
      >
    </template>
    <template #item.website="{ item }">
      <a :href="item.website" target="_blank">{{ item.website }}</a>
    </template>
    <template #item.counterReleaseSum="{ item }">
      <v-tooltip
        bottom
        v-for="(reports, version) in item.counterReports"
        :key="version"
      >
        <template #activator="{ on }">
          <span v-on="on">
            <v-chip
              class="mr-2"
              label
              :color="version === '5' ? '#cde9fa' : '#cbfcdc'"
              small
              >{{ version }}</v-chip
            >
          </span>
        </template>
        <span>
          <span class="font-weight-bold">COUNTER {{ version }}</span
          >:
          <span
            v-text="$tc('label.counter.reportNumber', reports.length)"
          ></span>
          <div v-text="reportList(reports)" class="mt-3"></div>
        </span>
      </v-tooltip>
    </template>
    <template #item.contact.form_url="{ item }">
      <a :href="item.contact.form_url" target="_blank" v-if="item.contact">{{
        item.contact.form_url
      }}</a>
    </template>
    <template #item.contact.phone="{ item }">
      <a :href="'tel:' + item.contact.phone" v-if="item.contact">{{
        item.contact.phone
      }}</a>
    </template>
    <template #item.contact.email="{ item }">
      <a :href="'mailto:' + item.contact.email" v-if="item.contact">{{
        item.contact.email
      }}</a>
    </template>
    <template #item.host_types="{ item }">
      <ul class="undecorated">
        <li v-for="hostType in item.host_types" :key="hostType.name">
          {{ hostType.name }}
        </li>
      </ul>
    </template>
  </v-data-table>
</template>

<script>
import CounterAPI from "../lib/api";

export default {
  name: "PlatformList",

  props: {
    platforms: { required: true, type: Array },
    loading: { type: Boolean, default: false },
    hideContacts: { type: Boolean, default: false },
  },

  data() {
    return {
      api: new CounterAPI(process.env.COUNTER_API_ROOT),
      columnSet: "reports",
      filterText: "",
    };
  },

  computed: {
    headers() {
      let ret = [
        { text: this.$t("label.platform.abbrev"), value: "abbrev" },
        { text: this.$t("label.platform.name"), value: "name" },
        { text: this.$t("label.platform.hostType"), value: "host_types" },
      ];
      if (this.columnSet === "reports") {
        ret = [
          ...ret,
          {
            text: this.$t("label.platform.contentProviderName"),
            value: "content_provider_name",
          },
          {
            text: this.$t("label.info.website"),
            value: "website",
            cellClass: "text-caption",
          },
          {
            text: this.$t("label.counter.releases"),
            value: "counterReleaseSum",
            sortable: true,
          },
        ];
      } else {
        const base = { class: "text-capitalize" };
        ret = [
          ...ret,
          {
            text: this.$t("label.contact.person"),
            value: "contact.person",
            cellClass: "text-caption",
            ...base,
          },
          {
            text: this.$t("label.contact.email"),
            value: "contact.email",
            cellClass: "text-caption",
            ...base,
          },
          {
            text: this.$t("label.contact.phone"),
            value: "contact.phone",
            cellClass: "text-caption",
            ...base,
          },
          {
            text: this.$t("label.contact.url"),
            value: "contact.form_url",
            cellClass: "text-caption",
            ...base,
          },
        ];
      }
      return ret;
    },
  },

  methods: {
    reportList(reports) {
      return reports.join(", ");
    },
  },
};
</script>

<style scoped>
.undecorated {
  list-style: none;
  padding-left: 0;
}
</style>
