<template>
  <v-container
    fluid
    class="my-md-12 px-md-6 mx-xl-auto"
    style="max-width: 1600px"
  >
    <v-row>
      <v-col>
        <h2
          v-text="$t('title.platforms')"
          class="text-md-h4 text--secondary"
        ></h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PlatformList :platforms="platforms" :loading="loading" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlatformList from "../components/PlatformList";
import CounterAPI from "../lib/api";
export default {
  name: "Home",

  components: { PlatformList },

  data() {
    return {
      api: new CounterAPI(process.env.COUNTER_API_ROOT),
      platforms: [],
      loading: false,
      columnSet: "reports",
      filterText: "",
    };
  },

  methods: {
    async getPlatforms() {
      this.loading = true;
      try {
        this.platforms = await this.api.getPlatforms();
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getPlatforms();
  },
};
</script>
