import axios from "axios";

class CounterAPI {
  constructor(apiUrl, apiVersion = "v1") {
    this.apiUrl = apiUrl;
    if (!this.apiUrl.endsWith("/")) {
      this.apiUrl += "/";
    }
    this.apiVersion = apiVersion;
  }

  constructApiUrl(path) {
    return `${this.apiUrl}api/${this.apiVersion}/${path}`;
  }

  async getPlatforms() {
    let resp = await axios.get(this.constructApiUrl("platform/"));
    let platforms = resp.data;
    platforms.map((platform) => this.postprocessPlatform(platform));
    return platforms;
  }

  async getOnePlatform(platformId) {
    let resp = await axios.get(this.constructApiUrl(`platform/${platformId}/`));
    let platform = resp.data;
    this.postprocessPlatform(platform);
    return platform;
  }

  async getReports() {
    let resp = await axios.get(this.constructApiUrl("counter-release/"));
    return resp.data;
  }

  async resolveDataUsageHost(apiUrl) {
    // takes the URL pointing to usageDataHost extracted from platform API
    // and returns the corresponding data
    let resp = await axios.get(apiUrl);
    return resp.data;
  }

  async getSushiServiceNotifications(sushiService) {
    // sushiService is an object as obtained through the API
    if (!sushiService) {
      return [];
    }
    const notificationCount = sushiService.notification_count ?? 0;
    if (notificationCount && sushiService.notifications_url) {
      let resp = await axios.get(sushiService.notifications_url);
      return resp.data;
    }
    return [];
  }

  async getAllNotifications() {
    let resp = await axios.get(this.constructApiUrl("notification/"));
    return resp.data.results;
  }

  async getUsageDataHosts() {
    let resp = await axios.get(this.constructApiUrl("usage-data-host/"));
    return resp.data;
  }

  async getOneUsageDataHost(dataHostId) {
    let resp = await axios.get(
      this.constructApiUrl(`usage-data-host/${dataHostId}/`)
    );
    return resp.data;
  }

  async getPlatformsForUsageDataHost(dataHostId) {
    let resp = await axios.get(
      this.constructApiUrl(`usage-data-host/${dataHostId}/platforms/`)
    );
    let platforms = resp.data;
    platforms.map((platform) => this.postprocessPlatform(platform));
    return platforms;
  }

  postprocessPlatform(platform) {
    platform.counterReports = {};
    platform.counterReleaseSum = 0;
    // only add those releases for which there is a SUSHI service
    // the `counterReleaseSum` makes it possible to sort platform from those without any COUNTER
    // release to those that have them all
    platform.sushi_services.forEach((item) => {
      platform.counterReports[item.counter_release] = [];
      platform.counterReleaseSum += Number.parseInt(item.counter_release, 10);
    });
    platform.reports
      .sort((a, b) => a.report_id.localeCompare(b.report_id))
      .forEach((rep) => {
        if (rep.counter_release in platform.counterReports) {
          platform.counterReports[rep.counter_release].push(rep.report_id);
        }
      });
  }

  async platformAddAuditData(platform) {
    const today = new Date().toISOString().substring(0, 7);
    for (let sushiService of platform.sushi_services) {
      const hostData = await this.resolveDataUsageHost(sushiService.data_host);
      sushiService.dataHost = hostData;
      sushiService.audits = hostData.audits.sort((a, b) =>
        (b.finish_date + b.start_date).localeCompare(
          a.finish_date + a.start_date
        )
      );
      // last audit
      const pastAudits = sushiService.audits.filter(
        (audit) => audit.finish_date <= today
      );
      if (pastAudits.length) {
        sushiService.lastAudit = pastAudits[0];
      }
      // upcoming audit
      const futureAudits = sushiService.audits
        .filter((audit) => !audit.finished)
        .sort((a, b) => a.start_date.localeCompare(b.start_date));
      if (futureAudits.length) {
        sushiService.upcomingAudit = futureAudits[0];
      }
    }
  }

  dataHostProcessAuditData(dataHost) {
    const today = new Date().toISOString().substring(0, 10);
    dataHost.audits.sort((a, b) =>
      (b.finish_date + b.start_date).localeCompare(a.finish_date + a.start_date)
    );
    // last audit
    const pastAudits = dataHost.audits.filter(
      (audit) => audit.finish_date <= today
    );
    if (pastAudits.length) {
      dataHost.lastAudit = pastAudits[0];
    }
    // upcoming audit
    const futureAudits = dataHost.audits
      .filter(
        (audit) =>
          !audit.finished &&
          (!dataHost.lastAudit ||
            audit.start_date > dataHost.lastAudit.finish_date)
      )
      .sort((a, b) => a.start_date.localeCompare(b.start_date));
    if (futureAudits.length) {
      dataHost.upcomingAudit = futureAudits[0];
    }
  }
}

export default CounterAPI;
