<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list dense>
        <v-subheader>{{ $t("title.counterRegistry") }}</v-subheader>
        <v-list-item-group>
          <v-list-item :to="{ name: 'Home' }" exact>
            <v-list-item-content>
              <v-list-item-title>{{ $t("title.platforms") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Notifications' }">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("title.notifications-short")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Usage data hosts' }">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("title.usageDataHosts")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark color="primary">
      <a href="https://www.projectcounter.org/" class="pt-2">
        <img
          src="@/assets/logo-inverted.png"
          alt="COUNTER logo"
          height="40px"
          class="pr-6 hidden-sm-and-down"
        />
      </a>
      <v-toolbar-title class="ms-8">
        <router-link
          :to="{ name: 'Home' }"
          class="title text-h4 font-weight-normal"
          style="font-variant: all-small-caps"
          >{{ $t("title.counterRegistry") }}</router-link
        >
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn :to="{ name: 'Home' }" text exact>{{
          $vuetify.breakpoint.smAndDown
            ? $t("title.platforms-short")
            : $t("title.platforms")
        }}</v-btn>
        <v-btn :to="{ name: 'Notifications' }" text>{{
          $vuetify.breakpoint.smAndDown
            ? $t("title.notifications-short")
            : $t("title.notifications")
        }}</v-btn>
        <v-btn :to="{ name: 'Usage data hosts' }" text>{{
          $t("title.usageDataHosts")
        }}</v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>
      <v-btn href="/backstage" icon>
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>

      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main app>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style lang="scss">
.v-toolbar__title {
  a.title {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.time {
  font-size: 87.5%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}
</style>
